import { Injectable } from '@angular/core';
import * as confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root',
})
export class ConfettiService {
  private canvas: HTMLCanvasElement | null = null;
  private myConfetti: any;

  constructor() {}

  public createCanvas(): HTMLCanvasElement | null {
    const container = document.getElementById('confetti-container');
    if (!container) {
      return null;
    }

    const oldCanvas = container.querySelector('canvas');
    if (oldCanvas) {
      oldCanvas.remove();
    }

    const canvas = document.createElement('canvas');
    Object.assign(canvas.style, {
      position: 'absolute',
      top: '0',
      left: '0',
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
    });

    const updateCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    container.appendChild(canvas);
    return canvas;
  }

  public launchConfetti(): void {
    this.canvas = this.createCanvas();
    if (!this.canvas) return;

    this.myConfetti = confetti.create(this.canvas, { resize: true });

    const randomInRange = (min: number, max: number): number => Math.random() * (max - min) + min;

    const defaults = {
      origin: { x: 0.5, y: 0.5 },
      spread: 70,
      startVelocity: 45,
      decay: 0.9,
      scalar: 1,
    };

    const fire = (particleRatio: number, customSettings: Partial<typeof defaults> = {}) => {
      this.myConfetti({
        ...defaults,
        ...customSettings,
        particleCount: Math.floor(200 * particleRatio),
      });
    };

    this.myConfetti({
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { x: 0.5, y: 0.5 },
    });

    const effects = [
      { ratio: 0.25, settings: { spread: 26, startVelocity: 55 } },
      { ratio: 0.2, settings: { spread: 60 } },
      { ratio: 0.35, settings: { spread: 100, decay: 0.91, scalar: 0.8 } },
      { ratio: 0.1, settings: { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 } },
      { ratio: 0.1, settings: { spread: 120, startVelocity: 45 } },
    ];

    effects.forEach((effect, index) => {
      setTimeout(() => {
        fire(effect.ratio, effect.settings);
      }, index * 50);
    });
  }
}
